.custom-color-link {
    color: black !important;
}

.custom-btn-none button.ant-drawer-close {
    display: none !important;
}

.ant-drawer-content-wrapper {
    width: 380px !important;
}

.d-leftMenu {
    display: none;
}

.ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
    display: none;
}

.custome-btne:where(.css-dev-only-do-not-override-zcfrx9).ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.06);
}