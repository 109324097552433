.ant-drawer-body {
    background-color: #F5F5F5;
}

.bg-linear {
    background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);

}


.border-096DD9 {
    border: 1px solid #096DD9;
}

.border-EC2D30 {
    border: 1px solid #EC2D30
}

.custom-shadow {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
}

.d-none {
    display: none;
}

.border-b-EEEEEE {
    border-bottom: 1px solid #EEEEEE;
}

.custom-1-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.d-search-mobile {
    display: none;
}

.d-search {
    display: flex;
}

.d-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.d-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.w-input-notification {
    width: 25%;
}

.w-establish {
    width: fit-content;
}

/* Responsive */

/* sm */
@media only screen and (max-width: 640px) {
    .sm-disabled {
        display: none;
    }

    .sm-bg-login {
        background-image: linear-gradient(288deg, rgba(0, 85, 255, 1) 1.5%, rgba(4, 56, 115, 1) 91.6%);
    }

    .sm-w-full {
        width: 100%;
    }

}


/* md */
@media only screen and (max-width: 768px) {

    .ant-modal.custom-w-modal-upload {
        width: 80% !important;
    }
}


/* lg */
@media only screen and (max-width: 1024px) {
    .ant-modal.custom-w-modal-upload {
        width: 80% !important;
    }
}


/* xl */
@media only screen and (max-width: 1280px) {
    .ant-modal.custom-w-modal-upload {
        width: 80% !important;
    }

    .ant-modal.custom-w-modal-detail {
        width: 80% !important;
    }
}

.ant-table-cell.ant-table-cell-with-append {
    display: flex;
    gap: 4px;
}

.d-none-select-item span.ant-select-selection-item {
    display: none !important;
}