.custom-position .ant-pagination-options {
    position: absolute;
    right: 220px !important;
    top: -3px;
}

.custom-position-li li.ant-pagination-total-text {
    position: absolute;
    right: 70px !important;
    top: -3px;
}

/* reponsive */


@media only screen and (max-width:820px) {
    .d-notification {
        display: flex !important;
        flex-direction: column !important;
        justify-content: unset !important;
        align-items: unset !important;
        gap: 8px !important
    }

    .w-input-notification {
        width: 100% !important;
    }

    .w-establish {
        width: 100% !important;
    }
}

@media only screen and (max-width:1085px) {
    .w-popup {
        width: 330px !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-mobile {
        display: block !important;
    }

    .d-filter-mobile {
        display: flex !important;
    }

    .d-filter {
        display: none !important;
    }

    .d-respon {
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important
    }

    .d-option {
        display: none !important;
    }

    .d-search-mobile {
        display: flex !important;
    }

    .d-search {
        display: none !important;
    }

    .w-ranger-picker {
        width: 90% !important;
    }

    .d-document {
        display: flex !important;
        flex-direction: column !important;
        align-items: unset !important;
        justify-content: unset !important;
        gap: 8px !important
    }


}

@media only screen and (max-width: 835px) {
    .d-profile {
        display: flex !important;
        flex-direction: column !important;
    }

    .w-avatar {
        width: 40% !important;
    }

    .d-avatar {
        display: flex !important;
        justify-content: center !important;
    }

    .w-info {
        width: 45% !important;
    }
}

@media only screen and (device-width: 820px) and (device-height: 1180px) {
    .w-logo {
        width: 530px !important;
        display: block !important;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
        left: 18%;
    }
}

@media only screen and (max-width:2275px) {
    .d-logo {
        display: none !important;
    }

    .w-logo {
        width: 530px !important;
        display: block !important;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }
}

@media only screen and (max-width:640px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

}

@media only screen and (max-width:630px) {
    .d-table-upload {
        display: none !important;
    }

    .d-upload-moblie {
        display: block !important;
    }

    .d-table-version {
        display: none !important;
    }

    .d-versionm-mobile {
        display: flex !important;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }

    .w-content {
        width: 110px !important;
    }


}

@media only screen and (max-width:500px) {
    .custom-w {
        width: 75% !important;
        overflow-x: scroll !important;
    }

    .d-change-document {
        display: none !important;
    }

    .d-mobile-document {
        display: flex !important;
    }

    .d-chart {
        display: flex !important;
    }
}

@media only screen and (max-width: 576px) {
    :where(.css-dev-only-do-not-override-zcfrx9).ant-pagination .ant-pagination-options {
        display: block !important;
    }


}

@media only screen and (max-width:860px) {
    .d-return {
        display: none !important;
    }

    .d-operation {
        display: none !important;
    }

    .d-back {
        display: flex !important;
        width: 100% !important;
        justify-content: space-between !important;
        align-items: center !important;
        background: white !important;
        padding-top: 12px !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }



    .d-input-search-mobile {
        display: flex !important;
        gap: 8px !important;
    }

    .d-input-search {
        display: none !important;
        gap: unset !important
    }
}

@media only screen and (max-width:770px) {
    .d-back-all-file {
        display: flex !important;
    }

    .d-list-file {
        display: block !important;
    }

    .d-all-file {
        display: none !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}



@media only screen and (max-width: 412px) and (max-height: 915px) {
    .text-size {
        font-size: 10px !important;
    }

    .login-text>button {
        font-size: 10px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 300px !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* Iphone 11 */

@media only screen and (device-width: 414px) and (device-height: 896px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 350px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* Iphone 11 pro max */
@media only screen and (device-width: 414px) and (device-height: 896px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 350px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* iphone 12 pro */
@media only screen and (device-width: 390px) and (device-height: 844px) {
    .w-popup {
        width: 310px !important;
    }

    .w-content {
        width: 99px !important;
    }

    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 340px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* Iphone 12 & 13 */
/* Iphone 13 mini */
@media only screen and (device-width: 375px) and (device-height: 812px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 350px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* Iphone 13 & 13 pro */


/* Iphone 13 pro max */
@media only screen and (device-width: 428px) and (device-height: 926px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 350px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

/* Iphone 14 pro max */
@media only screen and (device-width: 430px) and (device-height: 932px) {
    .text-size {
        font-size: 14px !important;
    }

    .login-text>button {
        font-size: 14px !important;
        font-weight: 600;
    }

    .d-logo {
        display: none !important;
    }

    .position-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .w-logo {
        width: 100% !important;
        display: block;
        position: absolute;
        top: -250px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .w-form-login {
        width: 350px !important;
    }

    .d-leftMenu {
        display: block !important;
    }

    .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-zcfrx9.ant-drawer-open {
        display: block !important;
    }

    .display-file {
        display: none !important;
    }

    .display-search {
        display: block !important;
    }

    .d-title-file {
        display: none !important;
    }

    .d-title-Thaco {
        display: block !important;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .d-list-file-new {
        display: none !important;
    }

    .d-list-file-thaco {
        display: block !important;
    }

    .d-unit {
        display: flex !important;
    }

    .d-see-all {
        display: none !important;
    }

    .d-info-table {
        display: none !important;
    }

    .d-new-doc {
        display: flex !important;
    }

    .d-title {
        display: none !important;
    }

    .d-title-pagination {
        display: none !important;
    }

    .d-showmore {
        display: none !important;
    }

    .d-showmore-mobile {
        display: flex !important;
        justify-content: end !important;
    }
}

@media only screen and (device-width: 375px) {
    .w-popup {
        width: 285px !important;
    }
}