.libr-main{
  .libr-avt{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed #e0e0e0;
    border-radius: 5px;
    position: relative;
  }
  .libr-icon{
    font-size: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #1890ff;
  }
}
.libr-upload{
  .hidden{
    display: none !important;
  }
  .custom-file-upload{
    width: 200px;
    min-height: 150px;
    border: 1px dashed #e0e0e0;
    border-radius: 5px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
      width: 100%;
    }
  }
}
.libr-modal{
  .ant-btn-primary{
    background: #1890ff;
  }
  .ant-modal-footer{
    display: none;
  }
  .libr-list{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 8px;
    .libr-item{
      position: relative;
      cursor: pointer;
      border: 1px solid #e0e0e0;
      padding: 4px;
      border-radius: 5px;
      &:hover{
        border: 1px solid #1890ff;
        .ctr-del{
          display: flex;
        }
      }
      .libr-image{
        aspect-ratio: 1/1;
        object-fit: contain;
      }
      .ctr-del{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 12px;
        background: #fff;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        &:hover{
          color: red;
        }
        display: none;
      }
    }
  }
  .libr-modal-head{
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .ant-input-search{
      width: 100%;
      max-width: 320px;
    }
  }
  .libr-modal-foot{
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
