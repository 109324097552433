.app-regional-unit-table,
.app-management-unit-table,
.app-nationwide-unit-table,
.app-departmental-unit-table,
.app-promulgating-unit-table,
.app-management-document-table,
.app-report-unit-table,
.app-document-catalog-table,
.app-document-list-table,
.app-document-list-add-table,
.app-document-catalog-add-table,
.app-upload-report-table,
.app-report-statistics-table,
.app-user-list-table,
.app-management-role-update-table1,
.app-management-role-update-table2,
.app-log-activity-table,
.app-home-table,
.app-internal-documents-table,
.app-search-document-table,
.app-chart-table,
.app-document-list-update-detail,
.app-report-unit-update-table,
.app-news-list-table{
  .ant-table{
    overflow-y: hidden;
  }
  .ant-table-body{
    max-height: unset !important;
    overflow: auto scroll;
  }
}

.ant-table-wrapper .ant-table-expanded-row-fixed{
  margin: 0 -16px;
}


.app-detail-document-home-detail-table{
  overflow: auto;
}
// @media screen and (min-width: 1280px) {
//   .app-internal-documents-table .ant-table-body{
//     @media screen and (min-height: 600px) {
//       height: calc(100vh - 315px);
//     }
//   }
// }

@media screen and (min-width: 1200px) {
  .app-document-list-table{
    .ant-table-body{
      @media screen and (min-height: 900px) {
        height: calc(100vh - 733px);
      }
    }
  }
  .app-internal-documents-table {
    .ant-table-body {
      max-height: var(--table-height) !important;
    }
  }
}

.app-management-role-update-table1 .ant-table-body{
  @media screen and (min-height: 768px) {
    height: 190px;
  }
}

@media screen and (min-width: 991px) {
  .app-report-unit-update-table{
    .ant-table-body{
      @media screen and (min-height: 700px) {
        height: calc(100vh - 510px) !important;
      }
    }
  }

  .app-regional-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-management-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-nationwide-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-news-list-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 355px) !important;
      }
    }
  }
  .app-promulgating-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-management-document-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-report-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-document-catalog-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        max-height: calc(100vh - 350px) !important;
      }
    }
  }
  .app-document-list-add-table{
    .ant-table-body{
      @media screen and (min-height: 650px) {
        max-height: calc(100vh - 525px) !important;
      }
    }
  }
  .app-document-catalog-add-table{
    .ant-table-body{
      @media screen and (min-height: 650px) {
        max-height: calc(100vh - 465px) !important;
      }
    }
  }
  .app-upload-report-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 446px) !important;
      }
    }
  }
  .app-report-statistics-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 459px) !important;
      }
    }
  }
  .app-user-list-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 376px) !important;
      }
    }
  }
  .app-management-role-update-table2{
    .ant-table-body{
      // @media screen and (min-height: 900px) {
      //   height: calc(100vh - 801px) !important;
      // }
    }
  }
  .app-log-activity-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 340px) !important;
      }
    }
  }
  .app-search-document-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 332px) !important;
      }
    }
  }
  .app-document-list-update-detail{
    .ant-table-body{
      @media screen and (min-height: 650px) {
        height: calc(100vh - 525px) !important;
      }
    }
  }
  .app-departmental-unit-table{
    .ant-table-body{
      @media screen and (min-height: 600px) {
        height: calc(100vh - 350px) !important;
      }
    }
  }
}
@media screen and ( min-width: 1440px ) {
  .app-home-table .ant-table-body{
    @media screen and (min-height: 750px) {
      height: calc(100vh - 655px);
    }
  }
}
@media screen and ( max-width: 1440px ) {
  .app-home-table .ant-table-body{
    @media screen and (min-height: 650px) {
      height: calc(100vh - 565px);
    }
  }
}
@media screen and ( max-width: 1439px ) {
  .app-home-table .ant-table-body{
    @media screen and (min-height: 650px) {
      height: calc(100vh - 531px);
    }
  }
}
@media screen and ( max-width: 991px ) {
  .app-home-table .ant-table-body{
    height: auto;
    min-height: unset;
  }
}
@media screen and ( max-width: 767px ) {
  .app-search-document-table{
    display: none;
  }
}

.fix-text-150 {
  display: block;
  width: 150px;
  word-break: break-word;
  white-space: pre-wrap;
}
.fix-text-150v2 {
  display: block;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
}

.fix-text-100 {
  display: block;
  width: 100px;
  word-break: break-word;
  white-space: pre-wrap;
}
.fix-text-phongban{
  display: block;
  width: 150px;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--base-title-cl);
  text-transform: capitalize;
}

.fix-text-200 {
  display: block;
  width: 200px;
  word-break: break-word;
  white-space: pre-wrap;
}

.fix-text-role {
  display: flex;
  align-items: center;
  width: 150px;
  word-break: break-word;
  white-space: pre-wrap;
}

@media screen and (min-width: 1438px) {
  .app-chart-table .ant-table-body{
    @media screen and (min-height: 1080px) {
      height: calc(100vh - 807px);
    }
  }
}
@media screen and (max-width: 1438px) {
  .app-chart-table .ant-table-body{
    @media screen and (min-height: 900px) {
      height: calc(100vh - 645px);
    }
  }
}
@media screen and (max-width: 1278px) {
  .app-chart-table .ant-table-body{
    height: auto;
    min-height: unset;
  }
}
