.custom-menu-title {
  border: none !important;
}

.ant-layout-sider.ant-layout-sider-dark.sm-disabled.bg-white {
  min-width: 220px !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.sm-disabled {

  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.custom-menu-title .ant-menu-title-content {
  flex: none !important;
  width: fit-content;
  height: fit-content;
  line-height: 24px;
  text-align: left;
  padding: 4px 0;
}

.custom-menu-title .ant-menu-item.ant-menu-item-only-child {
  height: fit-content;
  overflow: hidden;
  white-space: inherit;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-expand-icon.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;
}

.custom-button-color:hover {
  color: #4096ff !important;
}

.disabled {
  display: none;
}

.ant-layout-sider.ant-layout-sider-dark.sm-disabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.ant-layout-header {
  border-bottom: 2px solid rgba(229, 229, 229, 0.677);

}

.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-switch.ant-switch-checked {
  background: #4096ff !important;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

/* sm */
@media only screen and (max-width: 640px) {
  .sm-disabled {
    display: none;
  }

  .sm-bg-login {
    background-image: linear-gradient(288deg, rgba(0, 85, 255, 1) 1.5%, rgba(4, 56, 115, 1) 91.6%);
  }

  .sm-w-full {
    width: 100%;
  }

  .sm-block {
    display: contents;
  }
}

.loading-overlay {
  position: fixed;
  /* Để nó nằm cố định trên màn hình */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* Một lớp mờ */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Đảm bảo nó nằm trên cùng */
}

.App {
  position: relative;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-three-bounce {
  display: flex;
  /* Sắp xếp các quả bóng theo chiều ngang */
}

.sk-child {
  width: 50px;
  /* Kích thước của mỗi quả bóng */
  height: 50px;
  border-radius: 50%;
  background-color: #3498db;
  /* Màu sắc của các quả bóng */
  margin: 0 5px;
  /* Khoảng cách giữa các quả bóng */
  animation: sk-bounce 2s infinite ease-in-out;
}

.sk-bounce1 {
  background-color: #3498db;
  /* Màu sắc của quả bóng 1 */
  animation-delay: -0.32s;
  /* Khoảng thời gian chờ trước khi bắt đầu xoay */
}

.sk-bounce2 {
  background-color: #e74c3c;
  /* Màu sắc của quả bóng 2 */
  animation-delay: -0.16s;
}

.sk-bounce3 {
  background-color: #2ecc71;
  /* Màu sắc của quả bóng 3 */
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

/* .w-popup {
  width: 400px !important;
} */

.shadow-see-more {
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.08);
}

:where(.css-dev-only-do-not-override-zcfrx9).ant-popover .ant-popover-inner {

  padding: unset !important;
}

/* BEGIN .custom-tab */
.custom-tab .ant-tabs-nav {
  padding-inline: 20px;
  margin: 0;
}
.custom-tab .ant-tabs-tab {
  padding-block: 8px;
}
.custom-tab .ant-tabs-tab :not(.nt-tabs-tab-active) {
  color: var(--neutral-400);
}
/* END .custom-tab */

/* BEGIN .header */
.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 76px;
}
.header-right .header-notification-button {
  margin-right: 8px;
}
/* END .header */

/* BEGIN .custom-popover */
.ant-popover-placement-bottomRight .w-popup {
  border-radius: 12px;
  width: 400px;
  max-width: calc(100vw - 66px);
}
.ant-popover-placement-bottomRight .ant-popover-inner {
  border-radius: 12px !important;
}
/* END .custom-popover */

/* BEGIN .notification */
.notification-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 4px;
  overflow-y: auto;
  height: 100%;
}
.notification-item {
  padding: 8px 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}
.notification-item:hover {
  background: var(--Primary-100, linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), #096DD9);
}
.notification-item-icon {
  flex: 0 0 24px;
  max-width: 24px;
}
/* END .notification */
