@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './assets/styles/update.css';

/* @import url('https://fonts.cdnfonts.com/css/myriad-pro'); */
/* @font-face {
  font-family: 'MyriadPro';
  src: url('./assets/font/MyriadPro/MyriadPro-Bold.woff') format('woff'),
       url('./assets/font/MyriadPro/MyriadPro-Bold.woff2') format('woff2'),
       url('./assets/font/MyriadPro/MyriadPro-Regular.woff') format('woff'),
       url('./assets/font/MyriadPro/MyriadPro-Regular.woff2') format('woff2');
} */
@font-face {
  font-family: 'MYRIADPROREGULAR';
  src: local('MYRIADPROREGULAR'), url('./assets/font2/MYRIADPRO-REGULAR.OTF') format('woff');
}

@font-face {
  font-family: 'MYRIADPROSEMIBOLD';
  src: local('MYRIADPROSEMIBOLD'), url('./assets/font2/MYRIADPRO-SEMIBOLD.OTF') format('woff');
}

@font-face {
  font-family: 'MYRIADPROBOLD';
  src: local('MYRIADPROBOLD'), url('./assets/font2/MYRIADPRO-BOLD.OTF') format('woff');
}

.ant-input{
  font-family: 'MYRIADPROREGULAR';
}

/* @font-face {
  font-family: 'MYRIADPRO-REGULAR';
  src: local('MYRIADPRO-REGULAR'), url('./assets/font/MYRIADPROREGULAR/MYRIADPRO-REGULAR.OTF') format('OTF');
} */

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_4410">Text with this font applied</p> */
/* .fontsforweb_fontid_4410 {
} */

html,
body {
  height: 100vh !important;
  width: 100vw !important;
  margin: 0;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-8,
.text-xs,
.text-sm,
.text-base,
.text-lg,
.text-xl,
.text-2xl,
.text-3xl,
.text-4xl,
.text-5xl,
h1,
h2,
h3,
h4,
h5,
h6,
td,
th,
div,
p,
span {
  font-family: 'MYRIADPROREGULAR';
  /* MYRIADPROREGULAR */
}

/* td,
th {
  text-align: left !important;
} */


::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 6px;
  width: 8px;
  height: 6px;
  width: 8px;
  max-height: 5px !important;
  width: 8px;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shadow-select-tree {
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08)
}

.text-center-ant-select .ant-select-selector {
  text-align: center !important;
  padding-top: 10px !important;
}

.text-center-ant-select-search .ant-select-selector {
  text-align: center !important;
  padding-top: 1px !important;
}

.ant-layout-content.css-dev-only-do-not-override-1m62vyb {
  overflow: auto;
}

.box-shadow-6 {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.button-29 {
  align-items: center;
  appearance: none;
  background: #096DD9;
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-1px);
  color: #fff !important;
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  color: #fff !important;
  transform: translateY(2px);
}

.ant-input {
  background-color: transparent;
}

.ant-table-thead tr .ant-table-cell {
  /* background-color: #EAECF0!important; */
  font-size: 16px;
}

.custom-input-ant .ant-form-item {
  margin-bottom: 0 !important;
}

.custom-td .ant-table-cell {
  overflow: hidden !important;
}

.custom-css-1-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.custom-css-1-line-block {
  display: block !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}




/* :where(.css-dev-only-do-not-override-zcfrx9).ant-menu-light .ant-menu-item-selected {
  color: #1677ff !important;
} */
.ant-menu-light,
.ant-menu-light>.ant-menu {
  color: #4b4b4b;
}

li.ant-menu-item {
  font-size: 16px;
  color: #4b4b4b;
}

li.ant-menu-item a {
  transition: none;
}

li.ant-menu-item.ant-menu-item-selected {
  color: #1677ff;
  font-weight: 600;
  background: #f3f8fd;
}

.ant-menu {
  font-size: 16px;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: #f3f8fd;
}

.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.ant-menu-light>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)>.ant-menu-submenu-title:hover,
.ant-menu-light>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)>.ant-menu-submenu-title:hover,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  color: #1677ff;
  font-weight: 600;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
  font-weight: 600;
}

.ant-drawer .ant-drawer-body {
  padding: 15px 15px;
  background: #fff;
}

.item-menu-mobie {
  padding: 14px 10px;
  font-size: 16px;
  border-radius: 12px;
}

.item-menu-mobie.active {
  background: #f3f8fd;
  color: #1677ff;
  font-weight: 600;
}

/* li.ant-menu-item:hover{
  color: #1677ff;
  background: #f3f8fd;
  font-weight: 600;
} */

:where(.css-dev-only-do-not-override-zcfrx9).ant-popover .ant-popover-inner {
  background-color: #8E8E8E !important;
  border-radius: 2px !important;
  padding: 2px !important;
}

:where(.css-dev-only-do-not-override-zcfrx9).ant-popover .ant-popover-inner {
  padding: unset !important;
}

:where(.css-dev-only-do-not-override-zcfrx9).ant-popover .ant-popover-inner-content {
  color: white !important;
}

:where(.css-dev-only-do-not-override-zcfrx9).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  inset-inline-end: unset !important;
  display: none !important;
}

:where(.css-dev-only-do-not-override-zcfrx9).ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
  border: unset !important;
}

li.ant-pagination-simple-pager {
  display: none !important;
}

/* th.ant-table-cell {
  border-right: 1px solid #EEEEEE;
} */
th.ant-table-cell {
  white-space: nowrap !important;
}

.personal-update-from {
  padding: 16px 24px;
}

.g-content {
  padding: 24px;
}

.personal-update-from .d-profile {
  display: grid;
  grid-template-columns: 96px 1fr;
  gap: 32px;
}

.personal-update-from .m-from {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.personal-update-from .m-items {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.personal-update-from .m-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.personal-update-from .m-title {
  padding: 16px;
}

.personal-update-from .m-footer {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -16px;
  padding: 8px 24px;
}

.personal-update-from .custom-file-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.personal-update-from .m-avatar {
  aspect-ratio: 1/1;
  max-width: 96px;
}

.personal-update-from .m-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .search-document-main */
.search-document-main {
  padding: 16px 24px;
  background: #fff;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-radius: 8px 8px 0px 0px;
}

.search-document-main .f-from {
  display: grid;
  grid-template-columns: 1fr 202px;
  gap: 0px;
}

.search-document-main .f-ctr-from {
  padding-left: 0.5rem;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #096DD9;
  cursor: pointer;
}

.search-document-main .f-items {
  display: flex;
  column-gap: 32px;
  row-gap: 12px;
  flex-wrap: wrap;
}

.search-document-main .f-item {
  width: calc(50% - 16px);
}

.search-document-main .f-item {
  display: grid;
  grid-template-columns: 110px calc(100% - 110px);
  align-items: center;
}

.search-document-main .f-label {
  color: #4B4B4B;
  font-family: var(--font-semibold);
}

.search-document-main .f-item.v2 {
  grid-template-columns: 116px calc(100% - 116px);
}

/* .search-document-main .f-input .ant-select-selector {
  padding: 5px 4px;
} */

/* .search-document-main .f-input-v2 {
  padding: 8px;
} */

.search-document-main .ant-select-single {
  height: auto;
}

.search-document-main .serach-input {
  padding: 8px 11px;
}

.search-document-main .serach-btn {
  padding: 7px 16px;
}

.main-personal-from.v2 .personal-update-from {
  width: 100%;
  max-width: 572px;
  background: #fff;
}

.main-personal-from.v2 .m-avatar {
  margin-bottom: 32px;
}

.fix-col-mtl {
  display: block;
  width: 100px;
  font-size: 12px;
  word-break: break-word;
  white-space: pre-wrap;
  color: var(--neutral-600);
}

.fix-col-ttl {
  display: block;
  width: 300px;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
  color: #096DD9;
}

.fix-col-dvbh {
  display: block;
  width: 150px;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
}

.fix-center-content {
  justify-content: center;
}

.fix-col-nhl {
  display: block;
  width: 80px;
  /* word-break: break-word; */
  white-space: pre-wrap;
}

.fix-col-knv {
  font-size: 14px;
  white-space: pre-wrap;
}

.fix-font-th {
  font-family: var(--font-semibold);
}

.fix-tb-pd.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 10px;
}

.fix-tb-pd.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead>tr>th {
  padding: 10px;
}

.log-activity-serach {
  display: flex;
  padding: 16px 24px;
  gap: 12px;
}

.log-activity-serach .b1 {
  width: calc(100% - 445px);
  display: flex;
  align-items: center;
  gap: 12px;
}

.log-activity-serach .b2 {
  width: 445px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.log-activity-serach .i-picker {
  width: 100%;
}

.page-document-list .w-Document-statistics {
  width: 50%;
}

.page-document-list .w-total-document {
  width: 50%;
}

.page-document-list .document-aside-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 12px;
}

.page-document-list .document-aside-item.active {
  background: #F3F8FD;
}

.page-document-list .document-aside-item.active .text-xs.neutral-600 {
  color: #096DD9;
}

.page-document-list .document-aside-list {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.fix-wrap {
  white-space: pre-wrap;
}

@media only screen and (max-width: 1370px) {
  .page-document-list .rp-1366 {
    margin-left: -22px;
  }
}

@media only screen and (max-width: 1366px) {
  .page-document-list .w-Document-statistics {
    width: 50% !important;
  }

  .page-document-list .w-total-document {
    width: 50% !important;
  }

  .page-document-list .d-document-list {
    flex-direction: row !important;
    gap: 24px;
  }

  .page-document-list .pt-responsive {
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 1199px) {
  .page-document-list .w-Document-statistics {
    width: 100% !important;
  }

  .page-document-list .w-total-document {
    width: 100% !important;
  }

  .page-document-list .d-document-list {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1085px) {
  .log-activity-serach {
    flex-direction: column;
  }

  .log-activity-serach .b1,
  .log-activity-serach .b2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100% !important;
  }

  .log-activity-serach .b1 h3 {
    font-size: 14px;
  }

  .log-activity-serach .b2 .text-sm {
    font-size: 14px;
  }

  .log-activity-serach .b1 .d-title,
  .log-activity-serach .b1 .d-search {
    display: flex !important;
  }

  .search-document-main .f-ctr-from {
    display: none;
  }

  .search-document-main .f-from {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .slider-with-arrow-outer.fix-pd {
    --item-padding-top: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .main-personal-from.v2 .personal-update-from .m-title {
    padding-left: 0;
    padding-right: 0;
  }

  .main-personal-from.v2 .personal-update-from .m-items {
    padding-left: 0;
    padding-right: 0;
  }

  .g-content {
    padding: 16px;
  }

  .search-document-main .f-item {
    width: 100%;
  }

  .search-document-main .f-from {
    display: block;
  }

  .search-document-main .f-item,
  .search-document-main .f-item.v2 {
    display: flex;
    align-items: unset;
    flex-direction: column;
  }

  .search-document-main {
    padding: 16px 16px;
    gap: 12px;
  }

  .search-document-main .gr-ctr {
    flex-wrap: wrap;
    justify-content: unset;
    gap: 12px;
  }

  .search-document-main .ctr-dropdow {
    margin-top: -48px;
    padding-right: 0;
  }

  .search-document-main .serach-btn {
    padding: 7px 12px;
  }

  .search-document {
    padding: 16px;
  }
}

/* end search-document-main */

/* sm */
@media only screen and (max-width: 640px) {
  .sm-disabled {
    display: none;
  }

  .sm-bg-login {
    background-image: linear-gradient(288deg, rgba(0, 85, 255, 1) 1.5%, rgba(4, 56, 115, 1) 91.6%);
  }

  .sm-w-full {
    width: 100%;
  }

  .sm-block {
    display: contents;
  }
}


/* md */
@media only screen and (max-width: 768px) {
  .personal-update-from.bg-white {
    background: none;
    padding: 0;
  }

  .personal-update-from .d-avatar {
    justify-content: flex-start !important;
  }

  .personal-update-from .w-avatar {
    width: 96px !important;
  }

  .personal-update-from .m-from {
    grid-template-columns: 1fr;
    gap: 14px;
  }

  .personal-update-from .m-from .m-info {
    background: #fff;
    border-radius: 8px;
  }

  .personal-update-from .d-profile {
    gap: 14px;
  }

  .main-personal-from .px-6.pt-6 {
    padding: 16px;
  }

  .personal-update-from .m-footer {
    margin: 0;
    margin-top: 16px;
    background-color: #fff;
    border-radius: 0px 0px 4px 4px;
  }
}


/* lg */
@media only screen and (max-width: 1024px) {
  .min-w-90 {
    min-width: 90px;
  }
}


/* xl */
@media only screen and (max-width: 1280px) {

  .log-activity-serach .b1,
  .log-activity-serach .b2 {
    width: 50%;
  }
}

.custom-w-select-tree .ant-select {
  width: 30% !important;
}

.custom-w-select-tree .ant-select-selector {
  width: 100% !important;
}
