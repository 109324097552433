.news-add-page {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin: clamp(12px, 2vw, 24px);
  padding: clamp(12px, 3.33vw, 40px);
  background: #FFFFFF;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    padding-block-end: 22px;
    border-bottom: 1px solid var(--neutral-200);

    .ant-btn {
      padding: 8px;
      color: var(--primary-600);
      border-color: var(--primary-600);
      height: auto;
      line-height: 21px;
      font-size: 16px;

      &:disabled,
      &.ant-btn-disabled {
        background: transparent;
        color: var(--primary-600);
        border-color: var(--primary-600);
        opacity: 0.3;
      }
    }
  }
  .ant-form {
    .ant-form-item-required {
      &::before {
        order: 1;
        margin-inline-start: 4px;
        font-family: var(--font-semibold);
      }
      &::after {
        display: none;
      }
    }
    .link-block {
      display: flex;
      align-items: center;
      gap: 8px 12px;
      flex-wrap: wrap;

      strong {
        &::after {
          content: "*";
          color: #ff4d4f;
          margin-inline-start: 4px;
        }
      }
      .ant-tag {
        cursor: pointer;
      }
    }
  }
}
.news-add-drawer {
  .ant-drawer-content-wrapper {
    max-width: 400px !important;
  }
  .ant-drawer-body {
    background: #F5F5F5;
    padding: 16px;
  }
  .ant-drawer-footer {
    text-align: right;

    .ant-btn {
      padding: 6px 8px;
      color: #FFFFFF;
      background: var(--primary-600);
      height: auto;
      font-size: 14px;
      line-height: 21px;
      font-family: var(--font-semibold);
    }
  }
  .ant-card-head,
  .ant-card-body {
    padding: 16px;
  }
  .ant-card-head {
    color: var(--neutral-600);
    font-family: var(--font-semibold);
    font-weight: normal;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--neutral-500);

    &::before,
    &::after {
      display: none;
    }
  }
}