:root {
  --neutral-200: #EEEEEE;
  --neutral-300: #E1E1E1;
  --neutral-400: #CACACA;
  --neutral-500: #8E8E8E;
  --neutral-600: #4B4B4B;
  --neutral-700: #1F1F1F;
  --primary-600: #096DD9;
  --primary-700: #096DD9;
  --white: #FFFFFF;
  --font-semibold: 'MYRIADPROSEMIBOLD';
  --font-bold: 'MYRIADPROBOLD';
}
.neutral-200 {
  color: var(--neutral-200);
}
.neutral-300 {
  color: var(--neutral-300);
}
.neutral-400 {
  color: var(--neutral-400);
}
.neutral-500 {
  color: var(--neutral-500);
}
.neutral-600 {
  color: var(--neutral-600);
}
.primary-600 {
  color: var(--primary-600);
}
.primary-700 {
  color: var(--primary-700);
}
.font-600 {
  font-family: var(--font-semibold);
}
.font-700 {
  font-family: var(--font-bold);
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* BEGIN .custom-breadcrumb */
.custom-breadcrumb.ant-breadcrumb {
  line-height: 1;
}
.custom-breadcrumb.ant-breadcrumb a,
.custom-breadcrumb.ant-breadcrumb .ant-breadcrumb-separator {
  color: var(--neutral-500);
}
.custom-breadcrumb.ant-breadcrumb a.active {
  color: var(--neutral-600);
}
.custom-breadcrumb.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 4px;
}
/* END .custom-breadcrumb */

/* BEGIN .content-shown-wrapper */
.content-shown-wrapper {
  padding: 16px 24px;
}
.content-shown-wrapper .custom-h {
  height: auto;
  flex-grow: 1;
}
.content-shown-wrapper .d-title-mobile {
  display: none;
}
.content-shown-wrapper,
.content-shown-wrapper > .overflow-hidden,
.content-shown-wrapper > .overflow-hidden .table-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.content-shown-wrapper > .overflow-hidden .ant-table-wrapper {
  flex-grow: 1;
}
.content-shown-wrapper .pagination-block {
  margin-block-start: auto;
  padding: 8px 24px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 0px 0px 4px 4px;
  background: var(--white);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
}
.content-shown-wrapper .pagination-block .ant-pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}
.content-shown-wrapper .pagination-block .ant-pagination-options {
  order: 0;
  margin-inline-start: 0;
}
.content-shown-wrapper .pagination-block .ant-pagination-total-text {
  order: 1;
  /* padding-inline-start: 4px; */
  margin-inline-end: 0;
  min-width: 140px;
}
.content-shown-wrapper .pagination-block .ant-pagination-prev,
.content-shown-wrapper .pagination-block .ant-pagination-next {
  min-width: 28px;
}
.content-shown-wrapper .pagination-block .ant-pagination-prev {
  order: 2;
  margin-inline-end: 0;
}
.content-shown-wrapper .pagination-block .ant-pagination-next {
  order: 4;
}
.content-shown-wrapper .pagination-block .ant-pagination-item-link {
  font-size: 14px;
  color: var(--neutral-500);
}
.content-shown-wrapper .pagination-block .ant-select-single {
  height: 36px;
}
.content-shown-wrapper .pagination-block .ant-select-single .ant-select-selector {
  color: var(--neutral-500);
  padding: 7px 5px;
}
.content-shown-wrapper .pagination-block .ant-select-single .ant-select-arrow {
  right: 5px;
  color: var(--neutral-500);
}
/* END .content-shown-wrapper */

/* BEGIN TABLE */
.custom-table.ant-table-wrapper .ant-table,
.custom-table.ant-table-wrapper table,
.custom-table.ant-table-wrapper .ant-table .ant-table-title,
.custom-table.ant-table-wrapper .ant-table .ant-table-header,
.custom-table.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child,
.custom-table.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
  border-radius: 0;
}

.custom-table.ant-table-wrapper .ant-table-thead >tr>th,
.custom-table.ant-table-wrapper .ant-table-column-title {
  color: var(--neutral-600);
  font-weight: normal;
  font-family: var(--font-semibold);
}
.custom-table.ant-table-wrapper .ant-table-thead >tr>th.text-center {
  text-align: center;
}

.custom-table.ant-table-wrapper .ant-table-cell,
.custom-table.ant-table-wrapper .ant-table-thead>tr>th,
.custom-table.ant-table-wrapper .ant-table-tbody>tr>th,
.custom-table.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0px -1px 0px 0px #EEE inset;
  border: none;
  height: 48px;
}

/* :where(.css-dev-only-do-not-override-zcfrx9).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-zcfrx9).ant-table-wrapper tfoot>tr>td {

} */

.custom-table.ant-table-wrapper .ant-table-tbody>tr>td .ant-btn-default {
  height: auto;
}

.custom-table.ant-table-wrapper .ant-table-thead .ant-table-column-sorter {
  color: var(--neutral-400);
}
/* END TABLE */

/* BEGIN d-title */
.d-title,
.d-title-mobile {
  color: var(--neutral-600);
}
/* END d-title */

/* BEGIN .folder-item */
.folder-item-content {
  transition: all .2s;
  flex-direction: column;
  gap: 2px;
}
.folder-item-icon {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 12px;
  background: var(--white);
}
.folder-item-bg-top {
  display: none;
}
.folder-item-title {
  font-size: 14px;
  text-align: center;
}
.folder-item-title-sub {
  display: none;
}
.folder-item .ant-badge-count {
  padding: 0;
  font-size: 10px;
  line-height: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(254, 99, 47, 0.1);
  border-radius: 50%;
}
.folder-item .ant-badge-count::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #FE632F;
}
.folder-item .ant-badge .ant-scroll-number .ant-scroll-number-only {
  height: auto;
}
@media screen and (min-width: 768px) {
  .folder-item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
  }
  .folder-item-content {
    border: thin solid transparent;
    background: linear-gradient(to right, #FDFDFD, #FFFFFF);
    position: relative;
    margin-block-start: 14px;
    max-height: 80px;
    padding: 16px 7px;
    flex-direction: row;
    background: var(--white);
    gap: 16px;
  }
  .folder-item-content::before {
    content: "";
    position: absolute;
    left: 0;
    top: -14px;
    z-index: -1;
    height: 94px;
    width: 130px;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), linear-gradient(160deg, #096DD9 -21.19%, rgba(9, 109, 217, 0.00) 67.77%);
  }
  .folder-item-icon {
    height: 48px;
    width: 48px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
  }
  .folder-item-icon:hover {
    background: linear-gradient(to right, #f1f7fc, #f2f8fd);
  }
  .folder-item:hover .folder-item-content {
    background: linear-gradient(to right, #f1f7fc, #f2f8fd);
    border-color: var(--primary-600);
  }
  .folder-item-bg-top {
    display: block;
  }
  .folder-item-title {
    font-size: 16px;
    text-align: left;
  }
  .folder-item-title-sub {
    display: block;
  }
}
/* END .folder-item */

/* BEGIN .modal-feedback */
.modal-feedback.ant-modal .ant-modal-content {
  padding: 0;
}
.modal-feedback.ant-modal .ant-modal-header {
  margin-bottom: 0;
}
.modal-feedback.ant-modal .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.30);
}
.modal-feedback .ant-input-textarea-show-count {
  position: relative;
}
.modal-feedback .ant-input-textarea-show-count .ant-input-data-count {
  bottom: 7px;
  right: 15px;
  background: var(--white);
  color: var(--neutral-400);
}
/* END .modal-feedback */

/* BEGIN .new-doc-item */
.new-doc-item {
  border-radius: 12px;
  background: linear-gradient(100deg, #FFF 7.42%, #FFF2EE 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 12px;
  gap: 8px;
}
/* END .new-doc-item */

@media screen and (min-width: 1440px) {
  .ant-layout-sider.ant-layout-sider-collapsed + .ant-layout-content .content-shown-wrapper-portal {
    padding-inline-start: 72px;
    padding-inline-end: 72px;
  }
  .content-shown-wrapper-portal .gap-4 {
    gap: 24px;
  }
  .content-shown-wrapper-portal .gap-3 {
    gap: 18px;
  }
}
