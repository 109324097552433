@import url(../src/assets/styles/update.scss);
@import url(../src/AppTable.scss);
.page-document-list-add{
  .dla-main{
    padding: 16px 24px;
    font-size: 14px;
    color: #4B4B4B;
  }
  .dla-row{
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 12px;
  }
  .dla-col{
    display: flex;
    flex-direction: column;
    width: calc(50% - 16px);
    gap: 12px;
    &.v2{
      width: 100%;
    }
    &.v3{
      width: 100%;
      gap: 18px;
      padding-top: 12px;
    }
  }
  .dla-form-group{
    display: flex;
    align-items: center;
    gap: 16px;
    &.fix-mtl-top{
      align-items: baseline;
    }
    &.v2{
      .dla-label{
        // font-weight: 600;
        // font-size: 16px;
        white-space: nowrap;
      }
    }
  }
  .dla-label{
    width: 115px;
    font-family: var(--font-semibold);
  }
  .dla-input{
    width: calc(100% - 131px);
    &.v1{
      display: flex;
      align-items: center;
      gap: 5px;
      .text{
        font-style: italic;
      }
    }
  }
  .dla-add-btn{
    display: flex;
    cursor: pointer;
    padding: 5px 10px;
    gap: 4px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #096DD9;
    color: #096DD9;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-thead tr .ant-table-cell{
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: 991px) {
    .dla-col{
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .dla-form-group{
      flex-wrap: wrap;
      row-gap: 3px;
    }
    .dla-label,
    .dla-input{
      width: 100%;
    }
  }
}

.search-document{
  .d-new-doc{
    padding-top: 24px;
    display: none;
  }

  @media screen and (max-width: 767px) {
    .d-new-doc{
      display: block;
    }
    .table-document{
      display: none;
    }
  }
}

.filter-document-list{
  .fdl-form{
    display: flex;
  }
  .fdl-row{
    width: calc(100% - 100px);
  }
  .fdl-more{
    width: 100px;
    color: #096DD9;
    &.mobie{
      display: none;
      width: 100%;
      justify-content: flex-end;
      .fdl-ctr{
        width: fit-content;
      }
    }
  }
  .fdl-ctr{
    height: 32px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }
  .fdl-row{
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;
  }
  .fdl-col{
    width: calc(50% - 12px);
  }
  .fdl-form-group{
    display: flex;
    align-items: center;
    gap: 12px;
    &.v2{
      .fdl-label{
        width: 110px;
      }
      .fdl-input{
        width: calc(100% - 122px);
      }
    }
  }
  .fdl-label{
    width: 83px;
    font-family: var(--font-semibold);
    color: var(--neutral-600);
  }
  .fdl-input{
    width: calc(100% - 95px);
  }

  @media screen and (max-width: 1199px) {
    .fdl-more{
      display: none;

      // &.mobie{
      //   display: flex;
      // }
    }
    .fdl-row{
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    .fdl-col{
      width: 100%;
    }
    .fdl-label{
      width: 110px;
    }
    .fdl-input {
      width: calc(100% - 122px);
    }
    .fdl-form-group{
      &.v2{
        .fdl-label{
          width: 110px;
        }
        .fdl-input{
          width: calc(100% - 122px);
        }
      }
    }
    .fdl-more{
      // display: none;

      &.mobie{
        display: flex;
      }
    }
    // .fdl-form {
    //   &:not(.expanded) {
    //     display: none;
    //   }
    // }
  }
  @media screen and (max-width: 767px) {
    .fdl-row{
      row-gap: 8px;
    }
    .fdl-form-group{
      display: flex;
      align-items: flex-start;
      gap: 4px;
      flex-direction: column;
      &.v2{
        .fdl-label{
          width: 100%;
        }
        .fdl-input{
          width: 100%;
        }
      }
    }
    .fdl-label{
      width: 100%;
    }
    .fdl-input {
      width: 100%;
    }
  }
}
.page-document-list{
  @media screen and (max-width: 1199px) {
    .mb-mod{
      flex-direction: column;
      .document-page-aside{
        width: 100%;
        max-width: unset;
        flex: auto;
      }
      .document-aside-list{
        flex-direction: row;
        white-space: nowrap;
        overflow-y: hidden;
      }
      .document-aside-item{
        gap: 24px;
      }
      .major-card{
        left: 0;
        top: 100%;
        width: 100%;
        display: block;
        max-height: unset;
      }
      .department-card{
        left: 0;
        top: calc(100% + 134px);
        width: 100%;
        display: block;
        max-height: unset;
      }
      .major-card-list{
        flex-direction: row;
        overflow-y: hidden;
        padding: 12px 0;
        white-space: nowrap;
      }

      @media screen and (max-width: 767px) {
        .title.line-clamp-2{
          white-space: pre-wrap;
        }
        .major-card-list{
          overflow-y: unset;
        }
      }
    }
  }
}

.pagination-block-v0 {
  padding: 8px 24px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 0px 0px 4px 4px;
  background: var(--white);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
}
@media screen and (min-width: 991px) {
  .h-flexfull{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .flex-grow-1{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

@media screen and ( max-width: 991px ) {
  .fix-rp-detail-document-home{
    .px3{
      display: none;
    }
  }
}
.fix-action-title{
  text-align: center;
  color: var(--neutral-600);
  font-weight: normal;
  font-family: var(--font-semibold);
}
.fix-drop-slider{
  .slick-track{
    display: flex;
  }
}

@media screen and (max-width: 1439px) {
  .page-chart .px1 {
    margin-bottom: 0;
    @media screen and (max-width: 1279px) {
      margin-bottom: 16px;
    }
  }

  .page-chart .pt0 {
    padding-top: 0;
  }

  .page-chart .pb0 {
    padding-bottom: 0;
  }

  .page-chart .gap0 {
    gap: 5px;
  }

  .page-chart .fix-height0 {
    height: 72px;
  }

  .page-chart .fix-height1 {
    height: 60px;
  }

  .page-chart .fix-h1 {
    height: 140px;
  }

  .page-chart .fix-h2 {
    height: 132px;
  }

  .page-chart .fix-size {
    font-size: 14px;
    line-height: 1rem;
  }
}
.page-document-list{
  .pl-4-picker .ant-picker-input{
    padding-left: 0 !important;
  }

  .fix-flex-100{
    flex: 100%;
  }
  .document-aside-list{
    height: auto;
    overflow-x: hidden;
  }
  @media screen and (max-width: 1199px) {
    .document-aside-list{
      height: auto;
      overflow-x: unset;
    }
    .major-card,
    .department-card{
      height: auto;
    }
  }
}
.fr-label{
  font-size: 14px;
  margin-bottom: 5px;
}
.text-no-wrap{
  white-space: nowrap;
}
@media screen and (max-width: 1440px) {
  .page-home{
    .px1 {
      margin-bottom: 0;
      @media screen and (max-width: 1279px) {
        margin-bottom: 16px;
      }
    }
    .pt0 {
      padding-top: 0;
    }

    .pb0 {
      padding-bottom: 0;
    }

    .gap0 {
      gap: 5px;
    }

    .fix-height0 {
      height: 72px;
    }

    .fix-height1 {
      height: 60px;
      &::before{
        height: 75px;
      }
    }

    .fix-h1 {
      height: 140px;
    }

    .fix-h2 {
      height: 132px;
    }

    .fix-size {
      font-size: 14px;
      line-height: 1rem;
    }
  }
}

.page-management-role-add{
  .h-from{
    display: flex;
    gap: 24px;
  }
  .h-col{
    width: 50%;
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .fix-hide-mobie{
    display: none;
  }
  .page-home .fix-height1{
    height: auto;
  }
}
.ant-select.text-center-ant-select.ant-select-single.ant-select-show-arrow,
.ant-select.text-center-ant-select-search.ant-select-single.ant-select-show-arrow{
  width: 70px;
}
.list-app-document-item{
  display: flex;
  gap: 24px;
  justify-content: space-evenly;
}
.app-document-item{
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 5px;
  .info{
    text-align: center;
  }
  .img{
    background: #fff;
    border-radius: 0.75rem;
    padding: 5px;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.fix-breadcrumb-wrapper{
  padding: 6px 24px;
  gap: 0px;
  height: auto;

  .fix-text{
    font-size: 16px;
    font-weight: 500;
  }
}
.fix-breadcrumb-wrapperv2{
  .fix-text{
    font-size: 16px;
    font-weight: 500;
  }
}
.fix-search-rp{
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  .items-center{
    align-items: baseline;
  }
  .f-col{
    width: calc(33.333% - 16px);
    &:nth-child(2){
      .flex.items-center{
        .w-full{
          width: calc(100% - 48px);
        }
      }
    }
  }
  .f-col{
    width: calc(33.333% - 16px);
    &:nth-child(2){
      &.flex.items-center{
        .w-full{
          width: calc(100% - 48px);
        }
      }
    }
  }
  .flex.items-center{
    .w-full{
      width: calc(100% - 70px);
    }
  }
}
.fix-d-none{
  display: none !important;
}

// .app-document-list-update-form{
//   height: calc(100vh - 300px);
//   overflow-x: hidden;
// }
.mod-bar-chart{
  position: relative;
  .bar-text{
    position: absolute;
    left: 100%;
    margin-left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.fix-padding-right-bar{
  padding-right: 30px !important;
}
.fix-h-icon{
  height: 25px;
  width: 20px;
  max-width: unset;
  object-fit: contain;
}
.app-table-file{
  table{
    width: 100%;
  }
  th,td{
    padding: 2px 10px !important;
  }
  .fix-name-file-in-table{
    word-break: break-word;
  }
}
.document-detail-summary{
  .d-onmobie{
    display: none;
  }
  @media screen and (max-width: 767px) {
    .d-ondes{
      display: none;
    }
    .d-onmobie{
      display: block;
    }
    .summary-content{
      padding-inline: 15px !important;
    }
  }
}
.fix-h-mota-role{
  min-height: 1rem;
}
.fix-col-ttl{
  &.fix-v-icon{
    display: flex;
    gap: 5px;
    align-items: baseline;
  }
}
.notification-item{
  justify-content: space-between;
  .fixdotnoti{
    width: 12px;
    height: 12px;
    max-width: unset;
    max-height: unset;
  }
}
.fix-col-mtlv2 {
  display: block;
  width: auto;
  font-size: 12px;
  word-break: break-word;
  white-space: pre-wrap;
  color: var(--neutral-600);
}
.fix-col-ttlv2 {
  display: block;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1rem;
  cursor: pointer;
  color: #096DD9;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fix-col-ttlv3 {
  display: flex;
  align-items: baseline;
  gap: 3px;
  line-height: 1rem;
  .text-x{
    display: block;
    width: auto;
    word-break: break-word;
    white-space: pre-wrap;
    cursor: pointer;
    color: #096DD9;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.fix-col-dvbhv2 {
  display: block;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.fix-col-line2 {
  display: block;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.fix-col-nhlv2 {
  display: block;
  width: auto;
  /* word-break: break-word; */
  white-space: pre-wrap;
}
.fix-label-blod{
  font-family: var(--font-semibold);
}
.folder-item .ant-badge-count::before{
  z-index: -1;
}
.text-center-ant-select .ant-select-selector{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.fix-select-danh-muc{
  width: calc(100% - 70px);
}
.pagination-block-v1 {
  margin-block-start: auto;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 0px 0px 4px 4px;
  background: #fafafa;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
}
.pagination-block-v1 .ant-pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pagination-block-v1 .ant-pagination-options {
  order: 0;
  margin-inline-start: 0;
}
.pagination-block-v1 .ant-pagination-total-text {
  order: 1;
  /* padding-inline-start: 4px; */
  margin-inline-end: 0;
  min-width: 140px;
}
.pagination-block-v1 .ant-pagination-prev,
.pagination-block-v1 .ant-pagination-next {
  min-width: 28px;
}
.pagination-block-v1 .ant-pagination-prev {
  order: 2;
  margin-inline-end: 0;
}
.pagination-block-v1 .ant-pagination-next {
  order: 4;
}
.pagination-block-v1 .ant-pagination-item-link {
  font-size: 14px;
  color: var(--neutral-500);
}
.pagination-block-v1 .ant-select-single {
  height: 36px;
}
.pagination-block-v1 .ant-select-single .ant-select-selector {
  color: var(--neutral-500);
  padding: 7px 5px;
}
.pagination-block-v1 .ant-select-single .ant-select-arrow {
  right: 5px;
  color: var(--neutral-500);
}
.fix-add-cat{
  display: flex !important;
  align-items: center;
  gap: 45px;
  .tree-v1{
    width: 250px;
  }
}
@media screen and (max-width: 991px) {
  .fix-add-cat{
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    .tree-v1{
      width: 250px;
    }
  }
  .flex.items-center.justify-between.fix-rps{
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
  }
}
.custom-table.ant-table-wrapper .ant-table-cell, .custom-table.ant-table-wrapper .ant-table-thead>tr>th, .custom-table.ant-table-wrapper .ant-table-tbody>tr>th, .custom-table.ant-table-wrapper .ant-table-tbody>tr>td{
  line-height: 1rem;
}
.custom-table.ant-table-wrapper .ant-table-thead>tr>th, .custom-table.ant-table-wrapper .ant-table-tbody>tr>th{
  text-align: center;
  .justify-between{
    justify-content: center;
  }
  .fix-font-th{
    justify-content: center;
  }

}
.ant-drawer-content-wrapper{
  width: calc(100vw - 50px) !important;
  max-width: 290px !important;
}
@media screen and (max-width: 767px) {
  .fix-mobie-rp{
    flex-wrap: wrap;
    .item2, .item3{
      width: calc(50% - 4px);
    }
    .item4{
      width: 100%;
    }
  }

  .fix-search-mobie{
    flex-wrap: wrap;
    .item2, .item3{
      width: calc(50% - 4px);
    }
  }
  .mb-mobie-2{
    margin-bottom: 15px;
  }
  .pagination-block-v1{
    padding: 8px 8px;
    gap: 4px;
    .ant-pagination{
      gap: 6px;
    }
  }
}
.custom-w-select-tree.type1 .ant-select{
  width: 50% !important;
}

.chart-donut .apexcharts-text.apexcharts-datalabel-value {
  transform: translateY(-15px);
}
@media screen and (max-width: 767px) {
  .chart-donut{
    width: 100%;
    max-width: 320px;
  }
}
@media screen and (min-width: 400px) {
  .chart-donut .apexcharts-legend.apx-legend-position-right{
    top: 50% !important;
    transform: translateY(-50%);
    bottom: auto !important;
  }
}
.fix-popover{
  width: 320px;
}
.fix-lable-1row{
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal{
    min-height: 18px;
    line-height: 18px;
  }
  .ant-select-tree .ant-select-tree-switcher{
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper{
    line-height: 18px;
  }
  .ant-select-tree .ant-select-tree-treenode{
    padding: 0 0 6px 0;
  }
  // .ant-select-tree-title{
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   cursor: pointer;
  //   display: block;
  //   display: -webkit-box;
  //   overflow: hidden;
  //   white-space: pre-wrap;
  //   width: auto;
  //   word-break: break-word;
  // }
}
.head-report-unit{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-x{
    display: grid;
    gap: 8px;
    grid-template-columns: 130px 290px 290px;
    align-items: center;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 260px 260px;
      .d-title{
        display: none;
      }
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 160px 160px;
      .d-title{
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    gap: 16px;
    .left-x{
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
.td-lib-img{
  width: 80px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 3px;
  img{
    width: 100%;
  }
}
.fix-size-edit-lib{
  &.libr-upload .custom-file-upload{
    width: 100%;
  }
}
.fix-bg-button{
  background: #096DD9;
}
.app-management-document-table .ant-spin-nested-loading{
  z-index: 1;
}
.ant-picker-ok{
  button{
    background: #096DD9;
  }
}
.news-add-drawer .ant-drawer-footer .ant-btn{
  &.btn-wait{
    background: rgba(254, 155, 14, 1);
    color: #fff;
    border: 1px solid rgba(254, 155, 14, 1);
    &:hover{
      background: rgba(254, 155, 14, 0.8);
      border: 1px solid rgba(254, 155, 14, 0.8);
    }
  }
  &.btn-publish{
    background: rgba(12, 157, 97, 1);
    color: #fff;
    border: 1px solid rgba(12, 157, 97, 1);
    &:hover{
      background: rgba(12, 157, 97, 0.8);
      border: 1px solid rgba(12, 157, 97, 0.8);
    }
  }
}
.list-tag-form{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.management-document-filter,
.document-catalog-filter{
  display: grid;
  grid-template-columns: auto 1fr 130px;
  gap: 24px;
  align-items: center;
}
.fix-row01{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.fix-row0-1{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.fix-row022{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
  background: #fff;
}
.fix-row02{
  display: flex;
  flex-grow: 1;
}
.fix-row-t1{
  display: flex;
}
.folder-item-mobile-grid.fix-mod{
  display: block;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 98px;
  .folder-item-mobile__title{
    padding-left: 2px;
    padding-right: 2px;
  }
  .custom-arrow{
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    z-index: 100;
    &.next-arrow{
      left: auto;
      right: -30px;
    }
    svg{
      font-size: 30px;
      width: 35px;
    }
  }
  .folder-item-mobile{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.mail-template-content{
  display: grid;
  grid-template-columns: calc(100% - 336px) 320px;
  gap: 16px;
  .mail-params{
    position: relative;
    z-index: 1;
    &.active{
      .lot-x{
        display: block;
      }
    }
  }
  .lot-x{
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding-top: 8px;
    display: none;
  }
  .list-data{
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    max-height: 320px;
    padding: 8px;
    background: #fff;
    width: 100%;
    background: #fff;
    padding: 8px;
    border-radius: 8px;
    box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .lp-title{
    font-size: 16px;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    .icon-d{
      pointer-events: none;
      svg{
        width: 14px;
        height: 14px;
      }
    }
  }
  .lp-item{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    padding: 4px 8px;
    background: #f9f9f9;
    .icon{
      font-size: 16px;
      cursor: pointer;
      &:hover{
        color: #096DD9;
      }
    }
    .lb-info{
      display: flex;
      gap: 8px;
      align-items: center;
      .label{
        font-weight: 600;
      }
    }
  }
}
.ck.ck-powered-by{
  display: none !important;
}
